.App {
  text-align: center;
  
}

html * {
  font-family: 'Proxima Nova Regular', sans-serif !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

iframe {
  pointer-events: fill !important; /* this fucks stuff up sometimes*/
}

:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a:link {
  text-decoration: none;
}


a:visited {
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}


a:active {
  text-decoration: none;
}
.MuiIconButton-root:hover {
  background-color: rgba(235, 235, 227,1) !important;
}
.MuiButtonBase-root:hover {
  background-color: rgba(235, 235, 227, 1) !important;
  color: #286DA8 !important;
  font-weight: 600
}
.MuiScgIcon-root{
  fill: #286DA8 !important;     
}

body {
  background-color: rgba(255,255,246) !important;
  font-family: Sora !important;
}


.MuiPaper-root {
  background-color: rgba(255,255,246) !important;
}


h4 {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.MuiBottomNavigationAction-label.Mui-selected {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}



.MuiToggleButton-root.Mui-selected {
  /* background-color: rgba(235, 235, 227, 0.60) !important; */
  background-color: rgba(235, 235, 227, 0.60) !important;
  color: #286DA8 !important;
  
}

/* rgba(201, 81, 93, 0.75) */



/* What do I want for selected button */
/* Need to go around and change fill on icons buttons */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* iframe {
  pointer-events: none;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
